export default [
  {
    path: "/order/list/:type?",
    name: "MyOrder",
    meta: {
      title: "我的订单",
      keepAlive: false,
      auth: true
    },
    component: () => import("@views/order/MyOrder")
  },
    {
    path: "/order/proofingOrder",
    name: "proofingOrder",
    meta: {
      title: "我的打样",
      keepAlive: false,
      auth: true
    },
    component: () => import("@views/order/MyProofingOrder")
  },
  {
    path: "/order/logistics/:id",
    name: "Logistics",
    meta: {
      title: "物流信息",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/Logistics")
  },
  {
    path: "/order/proofingdetail/:id",
    name: "proofingDetail",
    meta: {
      title: "打样订单详情",
      keepAlive: false,
      auth: true
    },
    component: () => import("@views/order/ProofingOrderDetails")
  },
    {
    path: "/order/proofingparam",
    name: "proofingParam",
    meta: {
      title: "打样参数",
      keepAlive: false,
      auth: true
    },
    component: () => import("@views/order/ProofingParam")
  },
  {
    path: "/order/detail/:id",
    name: "OrderDetails",
    meta: {
      title: "订单详情",
      keepAlive: false,
      auth: true
    },
    component: () => import("@views/order/OrderDetails")
  },
  {
    path: "/order/submit/:id",
    name: "OrderSubmission",
    meta: {
      title: "提交订单",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/OrderSubmission")
  },
  {
    path: "/order/proofingsubmit",
    name: "proofingOrderSubmission",
    meta: {
      title: "提交订单",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/proofingOrderSubmission")
  },
  {
    path: "/order/status/:id/:status",
    name: "PaymentStatus",
    meta: {
      title: "支付状态",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/PaymentStatus")
  },
  {
    path: "/yinhangpay",
    name: "YinHangPay",
    meta: {
      title: "银行转账",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/YinHangPay")
  },
  {
    path: "/banksuccess",
    name: "BankSuccess",
    meta: {
      title: "转账成功",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/bankSuccess")
  },
  {
    path: "/order/refund/:id",
    name: "GoodsReturn",
    meta: {
      title: "申请退货",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/GoodsReturn.vue")
  },
  {
    path: "/order/refund_list",
    name: "ReturnList",
    meta: {
      title: "退货列表",
      keepAlive: true,
      auth: true
    },
    component: () => import("@views/order/ReturnList.vue")
  }
];
