<template>
  <div class="productSort">
    <form @submit.prevent="submitForm">
      <div class="header acea-row row-center-wrapper" ref="header">
        <div class="acea-row row-between-wrapper input">
          <span class="iconfont icon-sousuo"></span>
          <input
            type="text"
            placeholder="请按顺序输入型号，容量，倍率进行搜索"
            v-model="search"
          />
        </div>
      </div>
    </form>
    <div class="aside">

      <div class="fath" v-for="(item, index) in category" :key="index">
        <div 
        class="item acea-row row-center-wrapper" 
        :class="item.id === menyId ? 'on' : ''"
        @click="leftChange(item)">
          <span>{{ item.cate_name }}</span>
        </div>
        <div v-if="item.id === menyId && serest">
          <div 
            class="item-child acea-row row-center-wrapper"
            v-for="(subitem, subindex) in rightData.children" 
            :class="subitem.id === childMenyId? 'on':''"
            :key="subindex"  
            @click="leftItemChange(subitem)">
            <span>{{ subitem.cate_name }}</span>
          </div>
        </div>
      </div>


      <!-- <div
        class="item acea-row row-center-wrapper"
        :class="item.id === menyId ? 'on' : ''"
        v-for="(item, index) in category"
        :key="index"
        @click="leftChange(item)"
      >
        
      </div> -->
    </div>
    <div class="conter" @scroll.native="onScroll">
      <div class="listw type">
        <div class="title" ref="title">
          <div class="name">{{ rightChildData.name }}</div>
        </div>
        <div class="list acea-row">
          <div class="sidebar-list acea-row row-between">
            <div
              class="item-list"
              v-for="(child, index) in rightChildData.SubCategory"
              :key="index"
            >
              <router-link
                :to="{
                  path: '/goods_list',
                  query: { id: child.id, title: child.cate_name }
                }"
              >
                <span>{{ child.cate_name }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height:1.2rem;"></div>
  </div>
</template>
<script>
import debounce from "lodash.debounce";
import { getCategory,getSubCategory } from "@api/store";
import { trim } from "../../utils";

export default {
  name: "GoodsClass",
  components: {},
  props: {},
  data: function() {
    return {
      category: [],
      navActive: 0,
      search: "",
      lock: false,
      rightData:'',
      menyId:'',
      childMenyId:'',
      rightChildData:'',
      serest:true
    };
  },
  watch: {
    "$route.params.pid": function(n) {
      if (n) {
        this.activeCateId(n);
      }
    }
  },
  mounted: function() {
    document.addEventListener("scroll", this.onScroll, false);
    this.loadCategoryData();
  },
  methods: {
    //点击左侧子菜单
    leftItemChange(item) {
      var that = this;
      that.childMenyId = item.id;
      getSubCategory(that.childMenyId).then(res => {
        res.data.name = item.cate_name;
        that.rightChildData = res.data;
      })
    },
    //点击左侧菜单
    leftChange(e){
      var that = this;
      that.menyId = e.id;
      that.rightData = e;
      if(that.serest) {
        that.serest = false;
      } else {
        that.serest = true;
      }
    },
    activeCateId(n) {
      let index = 0;
      n = parseInt(n);
      if (!n) return;
      this.category.forEach((cate, i) => {
        if (cate.id === n) index = i;
      });

      if (index !== this.navActive) {
        this.asideTap(index);
      }
    },
    loadCategoryData() {
      getCategory().then(res => {
        this.category = res.data;
        this.rightData = res.data[0];
        this.childMenyId = this.rightData.children[0].id;
        this.menyId = res.data[0].id;
        this.$nextTick(() => {
          if (this.$route.params.pid) this.activeCateId(this.$route.params.pid);
          else this.onScroll();
        });
        getSubCategory(this.childMenyId).then(res => {
          res.data.name = this.rightData.children[0].cate_name;
          this.rightChildData = res.data;
        })
      });
    },
    submitForm: function() {
      var val = trim(this.search);
      if (val) {
        this.$router.push({
          path: "/goods_list",
          query: { s: val }
        });
        setTimeout(() => (this.search = ""), 500);
      }
    },
    asideTap(index) {
      const top =
        this.$refs.title[index].offsetTop -
        this.$refs.header.offsetHeight -
        window.scrollY;
      this.lock = true;
      window.scrollBy({ top, left: 0, behavior: "smooth" });
      this.navActive = index;
    },
    onScroll: debounce(function() {
      if (this.lock) {
        this.lock = false;
        return;
      }
      const headerHeight = this.$refs.header.offsetHeight,
        { scrollY } = window,
        titles = this.$refs.title;
        if(titles.reduce) {
           titles.reduce((initial, title, index) => {
        if (initial) return initial;
        const parent = title.parentNode || title.parentElement;
        if (
          scrollY + headerHeight + 15 <
          title.offsetTop + parent.offsetHeight
        ) {
          initial = true;
          this.navActive = index;
        }
        return initial;
      }, false);
        }
    }, 500)
  },
  beforeDestroy: function() {
    document.removeEventListener("scroll", this.onScroll, false);
  }
};
</script>
<style>
  .productSort { min-height:100% }
  .productSort .conter { min-height:100%; }
  .productSort .conter .listw.type { min-height:100% }
</style>
