<template>
  <div id="footer" class="acea-row row-middle">
    <!-- <router-link
      :to="item.url"
      class="item"
      :class="{ on: item.url === $route.path}"
      v-for="(item, index) in footerList"
      :key="index"
    >
    </router-link> -->
    <div
      @click="tabbarClick(index)"
      class="item"
      :class="{ on: item.url === $route.path }"
      v-for="(item, index) in footerList"
      :key="index"
    >
      <div
        v-if="item.icon1.length != 0"
        class="iconfont"
        :class="item.icon1 + ' ' + (item.url === $route.path ? item.icon2 : '')"
      ></div>
      <div :class="index == 2 ? 'f14' : ''">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  props: {},
  data: function () {
    return {
      footerList: [
        {
          name: "首页",
          icon1: "icon-shouye-xianxing",
          icon2: "icon-shouye",
          url: "/"
        },
        {
          name: "分类",
          icon1: "icon-yingyongchengxu-xianxing",
          icon2: "icon-yingyongchengxu",
          url: "/category"
        },
        // {
        //   name: "粤ICP备16099901号",
        //   icon1: "",
        //   icon2: "",
        //   url: ""
        // },
        {
          name: "购物车",
          icon1: "icon-caigou-xianxing",
          icon2: "icon-caigou",
          url: "/cart"
        },
        {
          name: "我的",
          icon1: "icon-yonghu-xianxing",
          icon2: "icon-yonghu",
          url: "/user"
        }
      ]
    };
  },
  methods: {
    tabbarClick: function (index) {
      // if (index == 2) {
      //   window.location.href = 'https://beian.miit.gov.cn/';
      // } else {
      //   this.$router.push({ path: this.footerList[index].url })
      // }
      this.$router.push({ path: this.footerList[index].url })
    }
  }
};
</script>

<style>
.f14 {
  font-size: 0.16rem !important;
}
</style>
