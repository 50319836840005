import request from "@utils/request";

/**
 * 首页
 * @returns {*}
 */
export function getHomeData() {
  return request.get("index", {}, { login: false });
}

/**
 * 文章 轮播列表
 * @returns {*}
 */
export function getArticleBanner() {
  return request.get("/article/banner/list", {}, { login: false });
}

/**
 * 文章分类列表
 * @returns {*}
 */
export function getArticleCategory() {
  return request.get("/article/category/list", {}, { login: false });
}

/**
 * 文章 热门列表
 * @returns {*}
 */
export function getArticleHotList() {
  return request.get("/article/hot/list", {}, { login: false });
}

/**
 * 文章列表
 * @returns {*}
 */
export function getArticleList(q, cid) {
  return request.get("/article/list/" + cid, q, { login: false });
}

/**
 * 分享
 * @returns {*}
 */
export function getShare() {
  return request.get("/share", {}, { login: false });
}

/**
 * 文章详情
 * @returns {*}
 */
export function getArticleDetails(id) {
  return request.get("/article/details/" + id, {}, { login: false });
}

/**
 * 获取微信sdk配置
 * @returns {*}
 */
export function getWechatConfig() {
  return request.get(
    "/wechat/config",
    { url: document.location.href },
    { login: false }
  );
}

/**
 * 获取微信sdk配置
 * @returns {*}
 */
export function wechatAuth(code, spread, login_type) {
  return request.get(
    "/wechat/auth",
    { code, spread, login_type },
    { login: false }
  );
}
/**
 * 获取快递公司
 * @returns {*}
 */
export function getLogistics() {
  return request.get("/logistics", {}, { login: false });
}

/**
 * 获取登陆logo
 * @returns {*}
 */
export function getLogo(type) {
  return request.get("/wechat/get_logo", { type: type }, { login: false });
}

/**
 * 获取图片base64
 * @retins {*}
 * */
export function imageBase64(image, code) {
  return request.post(
    "/image_base64",
    { image: image, code: code },
    { login: false }
  );
}

/**
 * 获取关注海报
 * @returns {*}
 */
export function follow() {
  return request.get("/wechat/follow", {}, { login: false });
}

/**
 * 获取城市列表
 * @returns {*}
 */
export function getCity() {
  return request.get("/city_list", {}, { login: false });
}


/**
 * 获取共享工厂规模，财产保险
 * @returns {*}
 */

export function getFactory() {
  return request.get("/factory/data", {}, { login: true });
}

/**
 * 申请加盟共享工厂
 * @returns {*}
 */

export function getFactoryCreate(data) {
  return request.post("/factory/create", data);
}


/**
 * 共享工厂详情
 * @returns {*}
 */

export function getFactoryDetail(data) {
  return request.post("/factory/detail", data);
}

/**
 *  附近工厂
 * @returns {*}
 */
export function getNearFactory(data) {
  return request.post("/factory/getNearFactory", data);
}

/**
 *  附近工厂详情
 * @returns {*}
 */
 export function getDetailById(id) {
  return request.get("/factory/detailById/" + id,{}, { login: false });
}

/**
 *  获取工厂类型
 * @returns {*}
 */
export function getProductType(){
  return request.get("factory/getProductType",{},{ login: false });
}

/**
 *  订制
 * @returns {*}
 */
export function getProductCustom(data) {
  return request.post("/customized/save", data);
}


/**
 *  选择打样
 * @returns {*}
 */
export function getProofing(data) {
  return request.post("/Proofing/get", data);
}


/**
 *  打样下拉选项参数
 * @returns {*}
 */
export function getProofingData(data) {
  return request.post("/Proofing/getData", data);
}
/**
 * 获取下拉所有公司
 */
export function getProofingCompanyData() {
  return request.get('/Proofing/getCompanyData');
}
/**
 * 获取下拉所有公司分类
 */
export function getProofingCompanyTypeData() {
  return request.get('/Proofing/getCompanyTypeData');
}
/**
 * 根据公司id获取下拉参数
 */
export function getProofingParamsByCompanyId(id) {
  return request.get('/Proofing/getPdata', {id});
}
/**
 * 根据公司分类获取下拉所有公司
 * @returns {*}
 */
export function getProofingParamsByCompanyBrand(data) {
  return request.post('/Proofing/getCompanyListData', data);
}
/**
 *  电池保护板数据提交
 * @returns {*}
 */
export function getBatteryBoardCreate(data) {
  return request.post("/BatteryBoard/Create", data);
}

/**
 *  获取电池保护板数据
 * @returns {*}
 */
export function getBatteryBoardData(data) {
  return request.post("/BatteryBoard/getData", data);
}

/**
 *  获取电池组下拉选项
 * @returns {*}
 */
export function getBatteryGroupData(data) {
  return request.post("/BatteryGroup/getData", data);
}

/**
 *  电池组数据提交
 * @returns {*}
 */
export function getBatteryGroupCreate(data) {
  return request.post("/BatteryGroup/Create", data);
}

/**
 *  锂电芯打样数据提交形成订单号
 * @returns {*}
 */
export function createBatteryGroupProofing(data) {
  return request.post("order/createBatteryGroupProofing", data);
}

/**
 *  打样价格数据
 * @returns {*}
 */
export function getCalc(data) {
  return request.post("/Proofing/getCalc", data);
}

/**
 *  打样订单确认
 * @returns {*}
 */
export function confirmProofing(data) {
  return request.post("/order/confirmProofing", data);
}

/**
 *  咨询热线
 * @returns {*}
 */
export function getSiteServicePhone() {
  return request.get("/get_site_service_phone", {},{ login: false });
}


/**
 *  首页
 * @returns {*}
 */
export function getIndexChange() {
  return request.get("/index", {},{ login: false });
}
