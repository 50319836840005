<template>
  <div class="productList" ref="container">
    <form @submit.prevent="submitForm">
      <div class="search acea-row row-between-wrapper">
        <div class="black" @click="returnChange()">
          <img src="@assets/images/icon_black.png" alt="" />
        </div>
        <div class="input acea-row row-between-wrapper">
          <span class="iconfont icon-sousuo"></span>
          <input placeholder="请按顺序输入型号，容量，倍率进行搜索" v-model="where.keyword" />
        </div>
      </div>
    </form>

    <div class="nav acea-row row-middle">
      <div class="item" :class="title ? 'on' : ''" @click="set_where(0)">
        <!-- {{ title ? title : "默认" }} -->
        综合
      </div>
      <div class="item" @click="set_where(2)">
        库存
        <img src="@assets/images/horn.png" v-if="stock === 0" />
        <img src="@assets/images/up.png" v-if="stock === 1" />
        <img src="@assets/images/down.png" v-if="stock === 2" />
      </div>
      <div class="item" @click="set_where(1)">
        价格
        <img src="@assets/images/horn.png" v-if="price === 0" />
        <img src="@assets/images/up.png" v-if="price === 1" />
        <img src="@assets/images/down.png" v-if="price === 2" />
      </div>
      <!-- <div class="item" :class="nows ? 'font-color-red' : ''" @click="set_where(3)">
        筛选
      </div> -->
    </div>

    <div class="list acea-row row-between-wrapper" ref="container">
      <div
        v-for="(item, index) in productList"
        class="item"
        :key="index"
        :title="item.store_name"
      >
        <p class="title ellipsis_1">{{ item.store_name }}</p>
        <div class="content acea-row row-left" @click="goDetail(item)">
          <div class="left">
            <img :src="item.image" />
          </div>
          <div class="right">
            <p>品牌：{{ item.brand_name }}</p>
            <p>型号：{{ item.model_type }}</p>
            <p>编号：{{ item.product_number }}</p>
            <p>规格：{{ item.spec }}</p>
            <p>库存：{{ item.stock }}</p>
            <p class="chakan" @click.stop="getVideLink(item.video_link)">查看数据文件</p>
          </div>
        </div>
        <div class="weibu acea-row row-between">
          <p>销售单价：</p>
          <div
            class="prices"
            :class="itemIndex == index + 1 ? 'on' : ''"
            @click="pricesChange(index)"
          >
            <p v-for="(subitem, subindex) in item.prisezte" :key="subindex">
              {{ subitem.nums }}+:<span>¥ {{ subitem.prices }}</span>
              <img
                v-if="subindex == 0"
                :class="itemIndex == index + 1 ? 'tran' : ''"
                src="@assets/images/icon_por_button.png"
              />
            </p>
          </div>
          <div class="cart">
            <img
              v-if="store.token"
              class="car"
              src="@assets/images/icon_car.png"
              alt=""
              @click="joinCart(index)"
            />
          </div>
        </div>
        <Product-popu
          v-on:changeFun2="changeFun2"
          :attr="item.attr"
          :storeInfo="item"
          :typename="'product'"
        ></Product-popu>
      </div>
    </div>

    <Loading :loaded="loadend" :loading="loading"></Loading>
    <div
      class="noCommodity"
      v-cloak
      v-if="productList.length === 0 && where.page > 1"
    >
      <div class="noPictrue">
        <img src="@assets/images/noGood.png" class="image" />
      </div>
    </div>
    <!-- <Recommend v-if="productList.length === 0 && where.page > 1"></Recommend> -->

    <div class="carl" @click="goCar()">
      <img src="@assets/images/icon_cal.png" alt="">
      <span>{{num}}</span>
    </div>

  </div>
</template>

<script>
// import Recommend from "@components/Recommend";
import ProductPopu from "@components/productPopu";
import { getProducts,getCount } from "@api/store";
import debounce from "lodash.debounce";
import Loading from "@components/Loading";
import { goShopDetail } from "@libs/order";
import $store from "../../store";
export default {
  name: "GoodsList",
  components: {
    // Recommend,
    Loading,
    ProductPopu
  },
  props: {},
  data: function() {
    const { s = "", id = 0, title = "" } = this.$route.query;

    return {
      store: $store.state.app,
      hostProduct: [],
      productList: [],
      Switch: true,
      where: {
        page: 1,
        limit: 8,
        keyword: s,
        sid: id, //二级分类id
        news: 0,
        priceOrder: "",
        stockOrder: ""
      },
      title: title && id ? title : "",
      loadTitle: "",
      loading: false,
      loadend: false,
      price: 0,
      stock: 0,
      nows: false,
      itemIndex: 0,
      num:0,
      attr: {
        cartAttr: false,
        productAttr: [],
        productSelect: {}
      }
    };
  },
  watch: {
    title() {
      this.updateTitle();
    },
    $route: {
      handler(to) {
        if (to.name !== "GoodsList") return;
        const { s = "", id = 0, title = "" } = to.query;
        if (s !== this.where.keyword || id !== this.where.sid) {
          this.where.keyword = s;
          this.loadend = false;
          this.loading = false;
          this.where.page = 1;
          this.where.sid = id;
          this.title = title && id ? title : "";
          this.nows = false;
          this.$set(this, "productList", []);
          this.price = 0;
          this.stock = 0;
          this.get_product_list();
          this.getNum();
        }
        if(to.query.keyword) {
          this.where.keyword = to.query.keyword
        }
      },
      immediate: true
    }
  },
  mounted: function() {
    this.updateTitle();
    this.get_product_list();
    this.getNum();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.get_product_list();
    });
  },
  methods: {
    //获取购物车数量
    getNum(){
      getCount().then(res=>{
        this.num = res.data.count;
      })
    },
    //进购物车
    goCar(){
      this.$router.push({ path: "/cart" });
    },
    //点击查看数
    getVideLink(path){
      var that = this;
      if(!path) {
        this.$dialog.error('未上传数据文件');
        return false;
      } else if(path.indexOf('pdf')==-1) {
        this.$dialog.error('文件格式不正确');
        return false;
      }
      that.$router.push({ path: '/pdfChange?path=' + path });
      // window.open(path, "_blank");
    },
    //  点击加入购物车按钮
    joinCart: function(index) {
      let that = this;
      that.productList[index].attr.cartAttr = true;
    },
    changeFun2: function(opt) {
      let that = this;
      that.productList[opt.index].attr.cartAttr = opt.value;
      this.getNum();
    },
    returnChange() {
      var that = this;
      that.$router.go(-1);
    },
    pricesChange(index) {
      var that = this;
      if (that.itemIndex) {
        that.itemIndex = "";
      } else {
        that.itemIndex = index + 1;
      }
    },
    // 商品详情跳转
    goDetail(item) {
      goShopDetail(item).then(() => {
        this.$router.push({ path: "/detail/" + item.id });
      });
    },
    updateTitle() {
      document.title = this.title || this.$route.meta.title;
    },
    get_product_list: debounce(function() {
      var that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      this.setWhere();
      let q = that.where;
      getProducts(q).then(res => {
        that.loading = false;
        for (var i in res.data) {
          res.data[i].prisezte = [];
          res.data[i].attr = { cartAttr: false, index: i };
          for (var j in res.data[i].batch_price) {
            var p = {
              nums: res.data[i].batch_price[j].split("-")[0],
              prices: res.data[i].batch_price[j].split("-")[1]
            };
            res.data[i].prisezte.push(p);
          }
        }
        that.productList.push.apply(that.productList, res.data);
        that.loadend = res.data.length < that.where.limit; //判断所有数据是否加载完成；
        that.where.page = that.where.page + 1;
      });
    }, 300),
    submitForm: function() {
      this.$set(this, "productList", []);
      this.where.page = 1;
      this.loadend = false;
      this.loading = false;
      this.get_product_list();
    },
    //点击事件处理
    set_where: function(index) {
      let that = this;
      switch (index) {
        case 0:
          return that.$router.push({ path: "/category" });
        case 1:
          if (that.price === 0) that.price = 1;
          else if (that.price === 1) that.price = 2;
          else if (that.price === 2) that.price = 0;
          that.stock = 0;
          break;
        case 2:
          if (that.stock === 0) that.stock = 1;
          else if (that.stock === 1) that.stock = 2;
          else if (that.stock === 2) that.stock = 0;
          that.price = 0;
          break;
        case 3:
          that.nows = !that.nows;
          break;
        default:
          break;
      }
      that.$set(that, "productList", []);
      that.where.page = 1;
      that.loadend = false;
      that.get_product_list();
    },
    //设置where条件
    setWhere: function() {
      let that = this;
      if (that.price === 0) {
        that.where.priceOrder = "";
      } else if (that.price === 1) {
        that.where.priceOrder = "asc";
      } else if (that.price === 2) {
        that.where.priceOrder = "desc";
      }
      if (that.stock === 0) {
        that.where.stockOrder = "";
      } else if (that.stock === 1) {
        that.where.stockOrder = "asc";
      } else if (that.stock === 2) {
        that.where.stockOrder = "desc";
      }
      that.where.news = that.nows ? "1" : "0";
    },
    switchTap: function() {
      let that = this;
      that.Switch = !that.Switch;
    }
  }
};
</script>
<style scoped>
.noCommodity {
  border-top: 3px solid #f5f5f5;
  padding-bottom: 1px;
}
/*.productList { min-height:100%; }*/
</style>
