<template>
  <div class="index" v-cloak>
    <div class="header acea-row row-center-wrapper">
      <router-link :to="'/category'" class="search acea-row row-middle">
        <span class="iconfont icon-xiazai5"></span
        >请按顺序输入型号，容量，倍率进行搜索
      </router-link>
    </div>

    <div class="menu-container acea-row row-between">
      <div
        class="list"
        v-for="(item, index) in indexData.web_banner"
        :key="index"
      >
        <router-link v-if="index !== 1 && index !== 2" :to="{ path: item.url }">
          <span>{{ item.title }}</span>
        </router-link>
        <a v-else @click="getPathChange(index, item.url)">
          <span>{{ item.title }}</span>
        </a>
      </div>
    </div>
    <div style="margin-top: 86px">
      <Loading :loaded="loaded" :loading="loading"></Loading>
    </div>
    <div v-if="indexData">
      <div class="slider-banner banner">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in indexData.banner" :key="index">
            <img :src="item.pic" />
          </swiper-slide>
          <div
            class="swiper-pagination paginationBanner"
            slot="pagination"
          ></div>
        </swiper>
      </div>
      <div class="notice-container acea-row row-left">
        <div class="title">
          <span>销售公告</span>
        </div>
        <div class="notice-img">
          <img src="@assets/images/icon_notic.png" alt="" />
        </div>
        <div class="content ellipsis_1">
          <div class="newProducts">
            <swiper class="swiper-wrapper" :options="swiperRoll">
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in indexData.roll"
                :key="index"
              >
                <span class="ellipsis_1">{{ item.info }}</span>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <!-- <div class="mode">
              <span>| 更多 ></span>
            </div> -->
      </div>
      <!-- <div class="info-container">
            <div class="in-title acea-row row-between">
              <div class="in-left">
                <p class="tit">今日特卖</p>
              </div>
              <div class="in-rigth">
                <p class="tit">销量排行</p>
              </div>
            </div>

            <div class="in-info">
              <swiper class="swiper-wrapper" :options="swiperInfo">
                  <swiper-slide class="swiper-slide" v-for="(item,index) in infoData" :key="index">
                    <div class="info acea-row row-between">
                      <div class="left">
                        <p v-for="(subitem,subindex) in item.bastBanner" :key="subindex">{{subitem.comment}}</p>
                      </div>
                      <div class="right">
                        <p class="ellipsis_1" v-for="(subitem,subindex) in item.benefit" :key="subindex">{{subitem.store_name}}</p>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
            </div>
          </div> -->
      <div class="info-container acea-row row-between">
        <div class="left">
          <p class="tit">今日特卖</p>
          <p
            class="info"
            v-for="(item, index) in indexData.bastBanner"
            :key="index"
          >
            <router-link :to="item.wap_link">{{ item.comment }}</router-link>
          </p>
        </div>
        <div class="right">
          <p class="tit">销量排行</p>
          <p
            class="info ellipsis_1"
            v-for="(item, index) in indexData.benefit"
            :key="index"
            @click="goDetail(item)"
          >
            {{ item.store_name }}
          </p>
        </div>
      </div>
      <div class="notice-container">
        <div class="title">
          <span>特别推荐</span>
        </div>
        <div class="recom">
          <swiper :options="swiperRecommend">
            <swiper-slide
              v-for="(item, index) in indexData.likeInfo"
              :key="index"
            >
              <img :src="item.image" @click="goDetail(item)" />
            </swiper-slide>
          </swiper>
        </div>
        <!-- <div class="recom acea-row row-between">
                <div class="item" v-for="(item,index) in indexData.likeInfo" :key="index" @click="goDetail(item)">
                  <img :src="item.image">
                </div>
              </div> -->
      </div>
      <div class="notice-container">
        <div class="title">
          <span>合作品牌</span>
        </div>
        <div class="brand acea-row row-between">
          <div class="item">
            <img src="@assets/images/LG.png" />
          </div>
          <div class="item">
            <img src="@assets/images/dengneng.png" />
          </div>
          <div class="item"><img src="@assets/images/sanyang.png" /></div>
          <div class="item"><img src="@assets/images/SX.png" /></div>
          <div class="item"><img src="@assets/images/yiwei.png" /></div>
          <div class="item"><img src="@assets/images/suoni.png" /></div>
          <div class="item"><img src="@assets/images/zhenhua.png" /></div>
          <div class="item"><img src="@assets/images/lishen.png" /></div>
          <div class="item"><img src="@assets/images/haisi.png" /></div>
          <div class="item"><img src="@assets/images/zhuoneng.png" /></div>
          <div class="item"><img src="@assets/images/PS.png" /></div>
          <div class="item"><img src="@assets/images/bike.png" /></div>
        </div>
      </div>
      <div class="notice-container acea-row row-between">
        <div
          class="left acea-row row-left"
          @click="callPhone(phone.site_phone)"
        >
          <div class="img">
            <img src="@assets/images/icon_tel.png" alt="" />
          </div>
          <div class="cont">
            <p>咨询热线</p>
            <p class="tel">{{ phone.site_phone }}</p>
          </div>
        </div>
        <div class="left acea-row row-left" @click="getServiceAuto()">
          <!-- <router-link :to="{path:'/customer/list'}" class="acea-row row-left"> -->
          <div class="img">
            <img src="@assets/images/icon_kefu.png" alt="" />
          </div>
          <div class="cont">
            <p>在线客服</p>
            <p class="tel">工作时间9:00-18:00</p>
          </div>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <div
      class="footer acea-row row-center-wrapper"
      style="height: 35px; padding-bottom: 10px"
      v-if="indexData"
    >
      <p
        class="acea-row row-center-wrapper"
        style="
          cursor: pointer;
          font-size: 12px;
          text-align: center;
          color: #333;
          line-height: 25px;
          margin-right: 10px;
        "
        @click="linkChangGongan()"
      >
        <img
          style="width: 16px; height: 16px; margin-right: 5px"
          src="@assets/images/gongan.png"
          alt=""
        />粤公网安备 44030602001484号
      </p>
      <!-- <p style="cursor: pointer; font-size:12px; text-align:center; color:#333; line-height:25px;" @click="linkChang()">粤ICP备16099901号</p> -->
    </div>
  </div>
</template>

<!-- <template>
  <div class="index" v-cloak>
    <div class="follow acea-row row-between-wrapper" v-if="followHid && isWeixin">
      <div>点击“立即关注”即可关注公众号</div>
      <div class="acea-row row-middle">
        <div class="bnt" @click="followTap">立即关注</div>
        <span class="iconfont icon-guanbi" @click="closeFollow"></span>
      </div>
    </div>
    <div class="followCode" v-if="followCode">
      <div class="pictrue"><img :src="followUrl" /></div>
      <div class="mask" @click="closeFollowCode"></div>
    </div>
    <div class="header acea-row row-center-wrapper">
      <div class="logo"><img :src="logoUrl" /></div>
      <router-link :to="'/search'" class="search acea-row row-middle">
        <span class="iconfont icon-xiazai5"></span>搜索商品
      </router-link>
    </div>
    <div class="slider-banner banner">
      <swiper :options="swiperOption" v-if="banner.length > 0">
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <img :src="item.pic" />
        </swiper-slide>
        <div class="swiper-pagination paginationBanner" slot="pagination"></div>
      </swiper>
    </div>
    <div class="nav acea-row">
      <router-link :to="item.wap_url ? item.wap_url : ''" class="item"  v-for="(item, index) in menus" :key="index">
        <div class="pictrue"><img v-lazy="item.pic" alt="img" /></div>
        <div>{{ item.name }}</div>
      </router-link>
    </div>
    <div class="news acea-row row-between-wrapper">
      <div class="pictrue"><img src="@assets/images/news.png" /></div>
      <div class="swiper-no-swiping new-banner">
        <swiper class="swiper-wrapper" :options="swiperRoll" v-if="roll.length > 0">
          <swiper-slide class="swiper-slide" v-for="(item, index) in roll" :key="index">
            <router-link :to="item.wap_url ? item.wap_url : ''" class="acea-row row-between-wrapper">
              <div class="text acea-row row-between-wrapper">
                <div class="label" v-if="item.show === '是'">最新</div>
                <div class="newsTitle line1">{{ item.info }}</div>
              </div>
              <div class="iconfont icon-xiangyou"></div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="specialArea acea-row row-between-wrapper" v-if="activityOne.wap_link !== undefined || activity.length">
      <router-link :to="activityOne.wap_link ? activityOne.wap_link : ''" v-if="activityOne.wap_link !== undefined" class="assemble">
        <img v-lazy="activityOne.pic" alt="img" />
        <div class="text">
          <div class="name">{{ activityOne.title }}</div>
          <div class="infor">{{ activityOne.info }}</div>
        </div>
      </router-link>
      <div class="list acea-row row-column-between">
        <router-link :to="item.wap_link ? item.wap_link : ''" class="item" v-for="(item, index) in activity" :key="index">
          <img v-lazy="item.pic" alt="img" />
          <div class="text">
            <div class="name">{{ item.title }}</div>
            <div class="infor">{{ item.info }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="wrapper" v-if="info.fastList.length > 0">
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">快速选择</div>
          <div class="line1">{{ info.fastInfo }}</div>
        </div>
        <router-link :to="'/category'" class="more"
          >更多<span class="iconfont icon-jiantou"></span
        ></router-link>
      </div>
      <div class="scroll-product">
        <swiper class="swiper-wrapper" :options="swiperScroll">
          <swiper-slide v-for="(item, index) in info.fastList" :key="index" class="swiper-slide">
            <router-link :to="{ path: '/goods_list', query: { id: item.id, title: item.cate_name } }">
              <div class="img-box">
                <img v-lazy="item.pic" alt="img" />
              </div>
              <div class="pro-info line1">{{ item.cate_name }}</div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="wrapper" v-if="info.bastList.length > 0 || info.bastBanner.length > 0">
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">精品推荐</div>
          <div class="line1">{{ info.bastInfo }}</div>
        </div>
        <router-link :to="{ path: '/hot_new_goods/' + 1 }" class="more"
          >更多<span class="iconfont icon-jiantou"></span
        ></router-link>
      </div>
      <div class="slider-banner boutique">
        <swiper class="swiper-wrapper" :options="swiperBoutique">
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in info.bastBanner"
            :key="index"
          >
            <router-link :to="item.wap_link ? item.wap_link : ''"
              ><img :src="item.img"
            /></router-link>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination paginationBoutique"></div>
      </div>
      <Good-list :good-list="info.bastList" :is-sort="false"></Good-list>
    </div>
    <div class="hotList" v-if="likeInfo.length > 0">
      <div class="hot-bg">
        <div class="title acea-row row-between-wrapper">
          <div class="text line1">
            <span class="label">热门榜单</span>根据销量、搜索、好评等综合得出
          </div>
          <router-link :to="{ path: '/hot_new_goods/' + 2 }" class="more">
            更多<span class="iconfont icon-jiantou"></span>
          </router-link>
        </div>
      </div>
      <div class="list acea-row row-middle">
        <router-link
          :to="{ path: '/detail/' + item.id }"
          class="item"
          v-for="(item, index) in likeInfo"
          :key="index"
        >
          <div class="pictrue">
            <img v-lazy="item.image" alt="img" />
            <img
              src="@assets/images/one.png"
              class="numPic"
              v-if="index === 0"
            />
            <img
              src="@assets/images/two.png"
              class="numPic"
              v-else-if="index === 1"
            />
            <img
              src="@assets/images/three.png"
              class="numPic"
              v-else-if="index === 2"
            />
          </div>
          <div class="name line1">{{ item.store_name }}</div>
          <div class="money font-color-red">
            ￥<span class="num">{{ item.price }}</span>
          </div>
        </router-link>
      </div>
    </div>
    <div v-if="newGoodsBananr">
      <div class="adver">
        <img v-lazy="newGoodsBananr" alt="img" />
      </div>
    </div>
    <div class="wrapper" v-if="info.firstList.length > 0">
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">
            首发新品<span class="new font-color-red">NEW~</span>
          </div>
          <div class="line1">{{ info.firstInfo }}</div>
        </div>
        <router-link :to="{ path: '/hot_new_goods/' + 3 }" class="more"
          >更多<span class="iconfont icon-jiantou"></span
        ></router-link>
      </div>
      <div class="newProducts">
        <swiper class="swiper-wrapper" :options="swiperProducts">
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in info.firstList"
            :key="index"
          >
            <div @click="goDetail(item)">
              <div class="img-box">
                <img v-lazy="item.image" alt="img" />
                <span
                  class="pictrue_log_medium pictrue_log_class"
                  v-if="item.activity && item.activity.type === '1'"
                  >秒杀</span
                >
                <span
                  class="pictrue_log_medium pictrue_log_class"
                  v-if="item.activity && item.activity.type === '2'"
                  >砍价</span
                >
                <span
                  class="pictrue_log_medium pictrue_log_class"
                  v-if="item.activity && item.activity.type === '3'"
                  >拼团</span
                >
              </div>
              <div class="pro-info line1">{{ item.store_name }}</div>
              <div class="money font-color-red">￥{{ item.price }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="wrapper" v-if="benefit.length > 0">
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">促销单品</div>
          <div class="line1">{{ info.salesInfo }}</div>
        </div>
        <router-link :to="'/promotion'" class="more"
          >更多<span class="iconfont icon-jiantou"></span
        ></router-link>
      </div>
    </div>
    <Promotion-good :benefit="benefit"></Promotion-good>
    <Coupon-window
      :coupon-list="couponList"
      v-if="showCoupon"
      @checked="couponClose"
      @close="couponClose"
    ></Coupon-window>

    <div style="height:1.2rem;"></div>
    <div>
      <iframe
        v-if="mapKey && !isWeixin"
        ref="geoPage"
        width="0"
        height="0"
        frameborder="0"
        scrolling="no"
        :src="
          'https://apis.map.qq.com/tools/geolocation?key=' +
            mapKey +
            '&referer=myapp'
        "
      >
      </iframe>
    </div>
  </div>
</template> -->

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@assets/css/swiper.min.css";
// import GoodList from "@components/GoodList";
// import PromotionGood from "@components/PromotionGood";
// import CouponWindow from "@components/CouponWindow";
import { getShare, follow, getSiteServicePhone, getIndexChange } from "@api/public";
import { serviceAuto, getUser } from "@api/user";
import cookie from "@utils/store/cookie";
import { openShareAll, wxShowLocation } from "@libs/wechat";
import { isWeixin } from "@utils/index";
import { goShopDetail } from "@libs/order";
import Loading from "@components/Loading";
const HAS_COUPON_WINDOW = "has_coupon_window";
const LONGITUDE = "user_longitude";
const LATITUDE = "user_latitude";
// const MAPKEY = "mapKey";
let vm = null;
export default {
  name: "Index",
  components: {
    swiper,
    swiperSlide,
    Loading
    // GoodList,
    // PromotionGood,
    // CouponWindow
  },
  props: {},
  data: function () {
    return {
      userInfo: '',
      phone: '',
      indexData: '',
      loading: false,
      loaded: false,
      newGoodsBananr: "",
      isWeixin: isWeixin(),
      followUrl: "",
      subscribe: false,
      followHid: false,
      followCode: false,
      showCoupon: false,
      logoUrl: "",
      banner: [],
      menus: [],
      roll: [],
      activity: [],
      activityOne: {},
      info: {
        fastList: [],
        bastBanner: [],
        firstList: [],
        bastList: []
      },
      lovely: [],
      benefit: [],
      couponList: [],
      infoData: [
        {
          bastBanner: [
            { comment: '14500 600mah  4.5元/只' },
            { comment: '18650 2000mah 6元/只' },
            { comment: '10440 200mah 4.0元/只' },
          ],
          benefit: [
            { store_name: 'HY16340-700mAh' },
            { store_name: '18650 容量锂电芯 2000mAh 1C' },
            { store_name: '10440锂电池' },
          ],
        },
        {
          bastBanner: [
            { comment: '14500 600mah  4.5元/只' },
            { comment: '18650 2000mah 6元/只' },
            { comment: '10440 200mah 4.0元/只' },
          ],
          benefit: [
            { store_name: 'HY16340-700mAh' },
            { store_name: '18650 容量锂电芯 2000mAh 1C' },
            { store_name: '10440锂电池' },
          ],
        },
        {
          bastBanner: [
            { comment: '14500 600mah  4.5元/只' },
            { comment: '18650 2000mah 6元/只' },
            { comment: '10440 200mah 4.0元/只' },
          ],
          benefit: [
            { store_name: 'HY16340-700mAh' },
            { store_name: '18650 容量锂电芯 2000mAh 1C' },
            { store_name: '10440锂电池' },
          ],
        }
      ],
      //轮播
      swiperOption: {
        pagination: {
          el: ".paginationBanner",
          clickable: true
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,
        on: {
          tap: function () {
            const realIndex = this.realIndex;
            vm.goUrl(realIndex);
          }
        }
      },
      //新闻
      swiperRoll: {
        direction: "vertical",
        autoplay: {
          disableOnInteraction: false,
          delay: 2000
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true
      },
      //今日特卖和销量排行
      swiperInfo: {
        direction: "vertical",
        autoplay: {
          disableOnInteraction: false,
          delay: 2000
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true
      },
      //特别推荐
      swiperRecommend: {
        // autoplay:false,
        autoplay: {
          disableOnInteraction: true,
          delay: 2500,
        },
        loop: true,
        loopedSlides: 3,
        speed: 1000,
        spaceBetween: 15,
        slidesPerView: 'auto',
        observer: true,
        observeParents: true,

      },
      mapKey: "",
    };
  },
  created () {
    vm = this;
  },
  mounted: function () {
    this.getFollow();
    this.getSerPhone();
    this.getIndexData();
    if (this.$store.state.app.token) {
      this.User();
    }
  },
  methods: {
    //点击脚部
    linkChang () {
      location.href = "https://beian.miit.gov.cn/";
    },
    linkChangGongan () {
      location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?spm=a2c4g.11186623.J_9220772140.108.955671501jVWHU";
    },
    //打样和生产订制都要先判断是否绑定手机号
    getPathChange (type, url) {
      //let that = this;
      if (type == 1) {
        this.$router.push({ path: url });
      } else if (type == 2) {
        this.$router.push({ path: url });
      }
      // if (that.userInfo.phone) {
      //   if (type == 1) {
      //     this.$router.push({ path: url });
      //   } else if (type == 2) {
      //     this.$router.push({ path: url });
      //   }
      // } else {
      //   this.$dialog.confirm({
      //     mes: "未绑定手机号，去绑定手机号?",
      //     opts: () => {
      //       this.$router.push({ path: "/user/binding" });
      //     }
      //   });
      // }
    },
    //获取用户信息
    User: function () {
      let that = this;
      getUser().then(res => {
        that.userInfo = res.data;
      });
    },
    //进入客服
    getServiceAuto () {
      serviceAuto().then(res => {
        console.log(res);
       // this.$router.push("https://work.weixin.qq.com/kfid/kfce5fb4acd32b5c3d5");
        location.href ="https://work.weixin.qq.com/kfid/kfce5fb4acd32b5c3d5";
        // this.$router.push(
        //   '/customer/chat/' +
        //     res.data.to_uid
        //     // +
        //     // '/' +
        //     // productId +
        //     // (orderId ? '?orderId=' + orderId : '')
        // )
      });
    },
    //进入产品详情
    goDetail (item) {
      goShopDetail(item).then(() => {
        this.$router.push({ path: "/detail/" + item.id });
      });
    },
    //拨打电话
    callPhone: function (phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    },
    //获取首页数据
    getIndexData () {
      if (this.loading || this.loaded) return;
      this.loading = true;
      getIndexChange().then(res => {
        this.indexData = res.data;
        this.mapKey = res.data.tengxun_map_key;
        this.loading = false;
        if (!cookie.get(LATITUDE) && !cookie.get(LONGITUDE)) this.getWXLocation();
      }).catch(res => {
        console.log(res);
      })
    },

    //获取咨询热线
    getSerPhone () {
      getSiteServicePhone().then(res => {
        this.phone = res.data;
        cookie.set('phone', res.data);
      }).catch(res => {
        console.log(res);
      })
    },


    // 轮播图跳转
    goUrl (index) {
      let url = this.indexData.banner[index].wap_url;
      let newStr = url.indexOf("http") === 0;
      if (newStr) {
        window.location.href = url;
      } else {
        this.$router.push({
          path: url
        });
      }
    },
    getWXLocation () {
      if (isWeixin()) {
        wxShowLocation();
      } else {
        if (!this.mapKey)
          console.log("暂无法使用查看地图，请配置您的腾讯地图key");
        let loc;
        // if (_this.$route.params.mapKey) _this.locationShow = true;
        //监听定位组件的message事件
        window.addEventListener(
          "message",
          function (event) {
            loc = event.data; // 接收位置信息 LONGITUDE
            if (loc && loc.module == "geolocation") {
              console.log(1111)
              cookie.set(LATITUDE, loc.lat);
              cookie.set(LONGITUDE, loc.lng);
            } else {
              //113.761293,23.027574
              // console.log(2222)
              // cookie.set(LATITUDE, 23.027574);
              // cookie.set(LONGITUDE, 113.761293);

              cookie.remove(LATITUDE);
              cookie.remove(LONGITUDE);
              //定位组件在定位失败后，也会触发message, event.data为null
              console.log("定位失败");
            }
          },
          false
        );
      }
    },
    closeFollow () {
      this.followHid = false;
    },
    followTap () {
      this.followCode = true;
      this.followHid = false;
    },
    closeFollowCode () {
      this.followCode = false;
      this.followHid = true;
    },
    couponClose () {
      cookie.set(HAS_COUPON_WINDOW, 1);
    },
    getFollow () {
      follow()
        .then(res => {
          this.followUrl = res.data.path;
        })
        .catch(() => { });
    },
    setOpenShare () {
      if (isWeixin()) {
        getShare().then(res => {
          var data = res.data.data;
          var configAppMessage = {
            desc: data.synopsis,
            title: data.title,
            link: location.href,
            imgUrl: data.img
          };
          openShareAll(configAppMessage);
        });
      }
    }
  }
};
</script>
<style scoped>
.seamless-warp2 {
  overflow: hidden;
  min-height: 65px;
  width: 380px;
}

[v-cloak] {
  display: none;
}
.index {
  background-color: #fff;
  padding-bottom: 50px;
}
.index .follow {
  z-index: 100000;
}
.keep {
  text-align: center;
}
</style>
