<template>
<div class="user">
  <div class="my-header">
    <img src="@assets/images/icon_my_bg.png" alt="" />
    <!-- <img class="wx" src="@assets/images/icon_wx.png" alt="" /> -->
    
    <div class="my-info acea-row row-between">
      <div class="avater">
        <img :src="userInfo.avatar?  userInfo.avatar:'@assets/images/icon_avater.png'" alt="" />
      </div>
      <div class="info">
        <p>{{userInfo.nickname}}</p>
        <p class="txt">用户ID:{{userInfo.uid}}</p>
      </div>
    </div>
    
    <div class="out" v-if="!isWeixin">
      <button @click="logout">退出登录</button>
    </div>
    
  </div>



  <div class="list-container">
    <div class="my-order">
      <div class="ord">
        <router-link :to="'/order/list/'" class="acea-row row-between">
          <p>我的订单</p>
          <div class="iconfont icon-jiantou"></div>  
        </router-link>
      </div>
    </div>
    <section id="dy_focus" class="dy-focus">
      <ul class="focus-container col-4">
        <li>
            <router-link :to="{ path: '/order/list/' + 0 }">
              <img src="@assets/images/icon_daishenhe.png" alt="" />
              <span>待支付</span>
            </router-link>      
        </li>
        <li>
            <router-link :to="{ path: '/order/list/' + 1 }">           
              <img src="@assets/images/icon_daizhifu.png" alt="" />
              <span>待发货</span>
            </router-link>
        </li>
        <li>
            <router-link :to="{ path: '/order/list/' + 2 }">
                <img src="@assets/images/icon_daifahuo.png" alt="" />
                <span>待收货</span>
            </router-link>
        </li>
        <li>
           <router-link :to="{ path: '/order/list/' + 4 }">          
                <img src="@assets/images/icon_yifahuo.png" alt="" />
                <span>已完成</span>
            </router-link>
        </li>
      </ul>
    </section>
    
    
    <div class="my-list">
      <div class="ord-li acea-row row-left">
        <router-link :to="{ path: '/collection' }" class="acea-row row-left">
          <img src="@assets/images/icon_shoucang.png" alt="" />
          <p>收藏</p>
        </router-link>       
      </div>
      <div class="ord-li acea-row row-left">
        <router-link :to="{ path: '/order/proofingOrder' }" class="acea-row row-left">
          <img src="@assets/images/icon_proofing.png" alt="" />
          <p>我的打样</p>
        </router-link>       
      </div>
      <div class="ord-li acea-row row-left">
        <router-link :to="{ path: '/user/my_customer' }" class="acea-row row-left">
          <img src="@assets/images/icon_customer.png" alt="" />
          <p>我的生产订制</p>
        </router-link>
      </div>
    </div>
    <div class="my-list">
      <div class="ord-li acea-row row-left">
        <router-link :to="{path:'/user/data'}" class="acea-row row-left">
          <img src="@assets/images/icon_set_up.png" alt="" />
          <p>账号设置</p>
        </router-link>
      </div>
    </div>
  </div>
  <SwitchWindow
    v-on:changeswitch="changeswitch"
    :switchActive="switchActive"
    :login_type="userInfo.login_type"
  ></SwitchWindow>
  <GeneralWindow
    :generalActive="generalActive"
    @closeGeneralWindow="closeGeneralWindow"
    :generalContent="generalContent"
  ></GeneralWindow>
</div>
</template>
<script>
import { getUser,getMenuUser,getLogout } from "@api/user";
import { isWeixin } from "@utils";
import { clearAuthStatus } from "@libs/wechat";
import SwitchWindow from "@components/SwitchWindow";
import GeneralWindow from "@components/GeneralWindow";
// import cookie from "@utils/store/cookie";
// import store from "@/store";
const NAME = "User";
export default {
  name:NAME,
  components: {
    SwitchWindow,
    GeneralWindow
  },
  data(){
    return {
      userInfo: {},
      MyMenus: [],
      orderStatusNum: {},
      switchActive: false,
      isWeixin: false,
      generalActive: false,
      generalContent: {
        promoterNum: "",
        title: ""
      }
    }
  },
  watch: {
    $route(n) {
      if (n.name === NAME) this.User();
    }
  },
  mounted: function() {
    this.User();
    this.MenuUser();
    this.isWeixin = isWeixin();
    this.MenuUser();
  },
  methods:{
    changeswitch: function(data) {
      this.switchActive = data;
    },
    User: function() {
      let that = this;
      getUser().then(res => {
        that.userInfo = res.data;
        // that.orderStatusNum = res.data.orderStatusNum;
        this.generalContent = {
          promoterNum: `您在商城累计消费金额仅差 <span style="color: #ff8500;">${res
            .data.promoter_price || 0}元</span>即可开通推广权限`,
          title: "您未获得推广权限"
        };
      });
    },
    MenuUser: function() {
      let that = this;
      getMenuUser().then(res => {
        that.MyMenus = res.data.routine_my_menus;
      });
    },
    closeGeneralWindow(msg) {
      this.generalActive = msg;
    },
    //退出登录
    logout: function() {
      this.$dialog.confirm({
        mes: "确认退出登录?",
        opts: () => {
          getLogout()
            .then(res => {
              this.$store.commit("LOGOUT");
              clearAuthStatus();
              location.href = location.origin;
              console.log(res);
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    }
  }
}
</script>
<style type="text/css">
.user { padding-bottom:50px; }

</style>





<!-- <template>
  <div class="user">
    <div class="header bg-color-red acea-row row-between-wrapper">
      <div class="picTxt acea-row row-between-wrapper">
        <div class="pictrue"><img :src="userInfo.avatar" /></div>
        <div class="text">
          <div class="acea-row row-middle">
            <div class="name line1">{{ userInfo.nickname }}</div>
            <div class="member acea-row row-middle" v-if="userInfo.vip">
              <img :src="userInfo.vip_icon" />{{ userInfo.vip_name }}
            </div>
          </div>
          <router-link :to="'/user/data'" class="id" v-if="userInfo.phone">
            ID：{{ userInfo.uid || 0
            }}<span class="iconfont icon-bianji1"></span>
          </router-link>
          <router-link :to="'/user/binding'" class="binding" v-else>
            <span>绑定手机号</span>
          </router-link>
        </div>
      </div>
      <span
        class="iconfont icon-shezhi"
        @click="$router.push({ path: '/user/data' })"
      ></span>
    </div>
    <div class="wrapper">
      <div class="nav acea-row row-middle">
        <router-link :to="{ path: '/user/account' }" class="item">
          <div>我的余额</div>
          <div class="num">{{ userInfo.now_money || 0 }}</div>
        </router-link>
        <div
          @click="goPagey('/user/user_promotion')"
          class="item"
          v-if="userInfo.is_promoter === 1 || userInfo.statu === 2"
        >
          <div>当前佣金</div>
          <div class="num">{{ userInfo.brokerage_price || 0 }}</div>
        </div>
        <router-link :to="'/user/integral'" class="item" v-else>
          <div>当前积分</div>
          <div class="num">{{ userInfo.integral || 0 }}</div>
        </router-link>
        <router-link :to="'/user/user_coupon'" class="item">
          <div>优惠券</div>
          <div class="num">{{ userInfo.couponCount || 0 }}</div>
        </router-link>
      </div>
      <div class="myOrder">
        <div class="title acea-row row-between-wrapper">
          <div>我的订单</div>
          <router-link :to="'/order/list/'" class="allOrder">
            全部订单<span class="iconfont icon-jiantou"></span>
          </router-link>
        </div>
        <div class="orderState acea-row row-middle">
          <router-link :to="{ path: '/order/list/' + 0 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dfk.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.unpaid_count > 0"
                >{{ orderStatusNum.unpaid_count }}</span
              >
            </div>
            <div>待付款</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 1 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dfh.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.unshipped_count > 0"
                >{{ orderStatusNum.unshipped_count }}</span
              >
            </div>
            <div>待发货</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 2 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dsh.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.received_count > 0"
                >{{ orderStatusNum.received_count }}</span
              >
            </div>
            <div>待收货</div>
          </router-link>
          <router-link :to="{ path: '/order/list/' + 3 }" class="item">
            <div class="pictrue">
              <img src="@assets/images/dpj.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.evaluated_count > 0"
                >{{ orderStatusNum.evaluated_count }}</span
              >
            </div>
            <div>待评价</div>
          </router-link>
          <router-link :to="'/order/refund_list'" class="item">
            <div class="pictrue">
              <img src="@assets/images/sh.png" />
              <span
                class="order-status-num"
                v-if="orderStatusNum.refund_count > 0"
                >{{ orderStatusNum.refund_count }}</span
              >
            </div>
            <div>售后/退款</div>
          </router-link>
        </div>
      </div>
      <div class="myService">
        <div class="title acea-row row-middle">我的服务</div>
        <div class="serviceList acea-row row-middle">
          <template v-for="(item, index) in MyMenus">
            <div
              class="item"
              :key="index"
              @click="goPages(index)"
              v-if="item.wap_url"
            >
              <div class="pictrue">
                <img :src="item.pic" />
              </div>
              <div>{{ item.name }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <img src="@assets/images/support.png" class="support" />
    <div class="footer-line-height"></div>
    <SwitchWindow
      v-on:changeswitch="changeswitch"
      :switchActive="switchActive"
      :login_type="userInfo.login_type"
    ></SwitchWindow>
    <GeneralWindow
      :generalActive="generalActive"
      @closeGeneralWindow="closeGeneralWindow"
      :generalContent="generalContent"
    ></GeneralWindow>
  </div>
</template>

<script>
import { getUser, getMenuUser } from "@api/user";
import { isWeixin } from "@utils";
import SwitchWindow from "@components/SwitchWindow";
import GeneralWindow from "@components/GeneralWindow";
const NAME = "User";

export default {
  name: NAME,
  components: {
    SwitchWindow,
    GeneralWindow
  },
  props: {},
  data: function() {
    return {
      userInfo: {},
      MyMenus: [],
      orderStatusNum: {},
      switchActive: false,
      isWeixin: false,
      generalActive: false,
      generalContent: {
        promoterNum: "",
        title: ""
      }
    };
  },
  watch: {
    $route(n) {
      if (n.name === NAME) this.User();
    }
  },
  mounted: function() {
    this.User();
    this.MenuUser();
    this.isWeixin = isWeixin();
  },
  methods: {
    changeswitch: function(data) {
      this.switchActive = data;
    },
    User: function() {
      let that = this;
      getUser().then(res => {
        that.userInfo = res.data;
        that.orderStatusNum = res.data.orderStatusNum;
        this.generalContent = {
          promoterNum: `您在商城累计消费金额仅差 <span style="color: #ff8500;">${res
            .data.promoter_price || 0}元</span>即可开通推广权限`,
          title: "您未获得推广权限"
        };
      });
    },
    MenuUser: function() {
      let that = this;
      getMenuUser().then(res => {
        that.MyMenus = res.data.routine_my_menus;
      });
    },
    goPagey(url) {
      if (!this.userInfo.is_promoter && this.userInfo.statu == 1)
        return this.$dialog.toast({ mes: "您还没有推广权限！！" });
      if (!this.userInfo.is_promoter && this.userInfo.statu == 2) {
        return (this.generalActive = true);
      }
      this.$router.push({ path: url });
    },
    goPages: function(index) {
      let url = this.MyMenus[index].wap_url;
      if (url === "/user/user_promotion") {
        if (!this.userInfo.is_promoter && this.userInfo.statu == 1)
          return this.$dialog.toast({ mes: "您还没有推广权限！！" });
        if (!this.userInfo.is_promoter && this.userInfo.statu == 2) {
          return (this.generalActive = true);
        }
      }
      if (url === "/customer/index" && !this.userInfo.adminid) {
        return this.$dialog.toast({ mes: "您还不是客服！！" });
      }
      this.$router.push({ path: this.MyMenus[index].wap_url });
    },
    closeGeneralWindow(msg) {
      this.generalActive = msg;
    }
  }
};
</script>

<style scoped>
.footer-line-height {
  height: 1rem;
}
.order-status-num {
  min-width: 0.33rem;
  background-color: #fff;
  color: #ee5a52;
  border-radius: 15px;
  position: absolute;
  right: -0.14rem;
  top: -0.15rem;
  font-size: 0.2rem;
  padding: 0 0.08rem;
  border: 1px solid #ee5a52;
}

.pictrue {
  position: relative;
}
.switch-h5 {
  margin-left: 0.2rem;
}
.binding {
  padding: 0.05rem 0.2rem;
  background-color: #ca1f10;
  border-radius: 50px;
  font-size: 0.14rem;
  border: 1px solid #e8695e;
  color: #ffffff;
}
</style>
 -->