<template>
  <div class="shoppingCart">
    <div class="header acea-row row-center-wrapper" ref="header">
      <p>购物车</p>
      <p class="admin" v-if="adminShow" @click="adminChange">管理</p>
      <p class="admin" v-if="!adminShow" @click="adminChange">完成</p>
    </div>
    <div class="all-pro">
      <div class="select-btn">
        <div class="checkbox-wrapper">
          <label class="well-check">
            <input
              type="checkbox"
              v-model="isAllChecked"
              v-on:click="chooseAll"
              :disabled="checkboxed"
            />
            <i class="icon"></i>
            <span class="checkAll">全选</span>
          </label>
        </div>
      </div>
    </div>

    <div class="list-container">
      <div class="list" v-for="(item, index) in cartList.valid" :key="index">
        <div class="checkbox-wrapper">
          <label class="well-check">
            <input
              type="checkbox"
              name=""
              @change="singleChecked"
              :value="item.id"
              v-model="checkedCode"
            />
            <i class="icon"></i>
          </label>
        </div>
        <div class="content">
          <div class="info acea-row row-between">
            <div class="left">
              <img :src="item.productInfo.image" />
            </div>
            <div class="right">
              <p class="tit">{{ item.productInfo.store_name }}</p>
              <p class="num">产品编号：SXLI29E20200327</p>
            </div>
          </div>
          <div class="numinfo acea-row row-between">
            <div class="left">
              <p>数量：{{ item.cart_num }}</p>
              <div class="prices" :class="itemIndex == index + 1 ? 'on' : ''" @click="pricesChange(index)">
                <p v-for="(subitem,subindex) in item.prisezte" :key="subindex">
                  {{ subitem.nums }}+:<span>¥ {{ subitem.prices }}</span>
                   <img
                  v-if="subindex == 0"
                  :class="itemIndex == index + 1 ? 'tran' : ''"
                  src="@assets/images/icon_por_button.png"
                />
                </p>
              </div>
            </div>
            <div class="right acea-row row-between">
              <p class="heji">合计：￥{{ item.productInfo.price }}</p>
              <div class="mofi" @click="joinCart(index)">
                <p>修改</p>
              </div>
            </div>
          </div>
          <Product-popu
            v-on:changeFun2="changeFun2"
            :attr="item.attr"
            :storeInfo="item"
            :typename="'shop'"
          ></Product-popu>
        </div>
      </div>
      <div
        class="noCart"
        v-if="cartList.length === 0 && cartList.length === 0"
      >
        <div class="pictrue"><img src="@assets/images/noCart.png" /></div>
      </div>
    </div>
    <div class="car-btn acea-row row-right">
      <div class="left" v-if="adminShow">
        <div class="checkbox-wrapper">
          <label class="well-check">
            <input
              type="checkbox"
              v-model="isAllChecked"
              v-on:click="chooseAll"
              :disabled="checkboxed"
            />
            <i class="icon"></i>
          </label>
        </div>

        <p class="heji">总计：￥{{ totailPrice }}</p>
        <!-- <p class="youhui">优惠：￥{{ cartList.discountAmount }}</p> -->
      </div>
      <div class="right" v-if="adminShow" @click="placeOrder()">
        <p>结算（{{ checkedCode.length }}）</p>
      </div>
      <div class="right" v-else @click="delCartChange()">
        <p>删除（{{ checkedCode.length }}）</p>
      </div>
    </div>
  </div>
</template>
<script>
import ProductPopu from "@components/productPopu";
import { getCartList,postCartDel } from "@api/store";

export default {
  name: "ShoppingCart",
  data() {
    return {
      cartList: '',
      selectAll: false,
      totailPrice: 0,
      checkedCode: [], // 用于保存被选中的数据
      isAllChecked: false, // 正在进行中的数据是否被选中
      adminShow: true,
      firstJum: false,
      checkboxed:false,
      itemIndex: 0,
    };
  },
  components: {
    ProductPopu
  },
  watch: {
    $route(n) {
      if (n.name === "ShoppingCart") {
        // this.getCartList();
      }
    }
  },
  mounted() {
    let that = this;
    that.getCartList();
  },
  methods: {
    pricesChange(index) {
      var that = this;
      if (that.itemIndex) {
        that.itemIndex = "";
      } else {
        that.itemIndex = index + 1;
      }
    },
    //点击结算
    placeOrder: function() {
      let that = this;
      let id = that.checkedCode;
      if (id.length === 0) {
        that.$dialog.toast({ mes: "请选择购物车" });
        return false;
      }
      this.$router.push({ path: "/order/submit/" + id });
    },
    //  点击加入购物车按钮
    joinCart: function(index) {
      let that = this;
      that.cartList.valid[index].attr.cartAttr = true;
    },
    changeFun2: function(opt) {
      let that = this;
      that.cartList.valid[opt.index].attr.cartAttr = opt.value;
    },
    //删除购物车
    delCartChange: function() {
      var that = this;
      if (that.checkedCode.length == 0) {
        this.$dialog.toast({ mes: "请选择需要删除的购物车" });
        return false;
      }
      var data = {
        ids: that.checkedCode.toString()
      };
      if (that.firstJum == true) {
        return false;
      }
      that.firstJum = true;
      postCartDel(data)
        .then(function(res) {
          that.$dialog.toast({ mes: res.msg });
          that.firstJum = false;
          that.isAllChecked = false;
          that.getCartList();
        })
        .catch(function(res) {
          that.firstJum = false;
          console.log(res);
        });
    },
    //点击管理
    adminChange: function() {
      let that = this;
      if (that.adminShow) {
        that.adminShow = false;
      } else {
        that.adminShow = true;
      }
    },
    //获取购物车列表
    getCartList: function() {
      let that = this;
      getCartList()
        .then(function(res) {
          for (var i in res.data.valid) {
            res.data.valid[i].prisezte = [];
            res.data.valid[i].attr = { cartAttr: false, index: i };
            for (var j in res.data.valid[i].productInfo.batch_price) {
              var p = {
                nums: res.data.valid[i].productInfo.batch_price[j].split(
                  "-"
                )[0],
                prices: res.data.valid[i].productInfo.batch_price[j].split(
                  "-"
                )[1]
              };
              res.data.valid[i].prisezte.push(p);
            }
          }
          that.cartList = res.data;
          if(that.cartList.length==0) {
            that.checkboxed = true;
          }
        }) 
        .catch(function(res) {
          console.log(res);
          that.$dialog.error(res.msg);
        });
    },
    // 用户单选
    singleChecked: function() {
      var that = this;
      //判断每一个CheckBox是否选中，全选中让全选按钮选中
      that.totailPrice = 0;
      if (this.cartList.valid.length == this.checkedCode.length) {
        this.isAllChecked = true;
      } else {
        // 只要有一个checkbox不选中，让全选按钮不选中
        this.isAllChecked = false;
      }
      for (var i in this.cartList.valid) {
        if (this.checkedCode.indexOf(this.cartList.valid[i].id) > -1) {
          that.totailPrice = parseFloat((parseFloat(that.totailPrice.toFixed(2)) + parseFloat(that.cartList.valid[i].productInfo.price.toFixed(2))).toFixed(2));
        }
      }
    },
    // 用户全选
    chooseAll: function() {
      var that = this;
      if(that.cartList.length!=0) {
        that.totailPrice = 0;
        if (that.checkedCode.length !== 0) {
          that.checkedCode = [];
        }
        if (!that.isAllChecked) {
          that.cartList.valid.forEach(function(singlePersonList) {
            that.checkedCode.push(singlePersonList.id);
            that.totailPrice = parseFloat((parseFloat(that.totailPrice.toFixed(2)) +  parseFloat(singlePersonList.productInfo.price.toFixed(2))).toFixed(2));
          }, that);
        } else {
          that.checkedCode = [];
          that.totailPrice = 0;
        }
      } else {
        that.$dialog.toast({ mes: "购物车空空如也" });
        that.isAllChecked = false;
        return false;
      }
    }
  }
};
</script>

<style scoped>

.shoppingCart.router {
  min-height: 100%;
  background: #fff;
}
.shoppingCart .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.shoppingCart .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.shoppingCart .header .black img {
  width: 100%;
  height: 100%;
}
.shoppingCart .header p {
  text-align: center;
  color: #333;
}
.shoppingCart .header p.admin {
  position: absolute;
  right: 8px;
  top: 15px;
  font-size: 14px;
  color: #666;
}

.shoppingCart .all-pro {
  height: 45px;
  background: #f6f6f6;
  margin-top: 50px;
}
.shoppingCart .all-pro input[name="vehicle"] {
  position: absolute;
  visibility: hidden;
}
.shoppingCart .all-pro input[name="vehicle"] + label {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #0193e6;
  border-radius: 50%;
  background-color: #0193e6;
  margin-top: 12px;
  margin-left: 8px;
}
.shoppingCart .all-pro input[name="vehicle"]:checked + label:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 4px;
  width: 9px;
  height: 4px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(-45deg);
  -ms-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}
.shoppingCart .all-pro p {
  font-size: 15px;
  line-height: 45px;
  color: #505050;
  margin-left: 40px;
}

.noCart {
  margin-top: 0.17rem;
  padding-top: 0.1rem;
}

.noCart .pictrue {
  width: 4rem;
  height: 3rem;
  margin: 0.7rem auto 0.5rem auto;
}

.noCart .pictrue img {
  width: 100%;
  height: 100%;
}

</style>

<!-- <template>
  <div class="shoppingCart">
    <div class="labelNav acea-row row-around row-middle">
      <div class="item">
        <span class="iconfont icon-xuanzhong"></span>100%正品保证
      </div>
      <div class="item">
        <span class="iconfont icon-xuanzhong"></span>所有商品精挑细选
      </div>
      <div class="item">
        <span class="iconfont icon-xuanzhong"></span>售后无忧
      </div>
    </div>
    <div class="nav acea-row row-between-wrapper">
      <div>
        购物数量 <span class="num font-color-red">{{ count }}</span>
      </div>
      <div
        v-if="cartList.valid.length > 0"
        class="administrate acea-row row-center-wrapper"
        @click="manage"
      >
        {{ footerswitch ? "取消" : "管理" }}
      </div>
    </div>
    <div v-if="cartList.valid.length > 0 || cartList.invalid.length > 0">
      <div class="list">
        <div
          class="item acea-row row-between-wrapper"
          v-for="(item, index) in cartList.valid"
          :key="index"
        >
          <div class="select-btn">
            <div class="checkbox-wrapper">
              <label class="well-check">
                <input
                  type="checkbox"
                  name=""
                  value=""
                  :checked="item.checked"
                  @click="switchSelect(index)"
                />
                <i class="icon"></i>
              </label>
            </div>
          </div>
          <div class="picTxt acea-row row-between-wrapper">
            <div
              class="pictrue"
              @click="$router.push({ path: '/detail/' + item.product_id })"
            >
              <img
                :src="item.productInfo.attrInfo.image"
                v-if="item.productInfo.attrInfo"
              />
              <img :src="item.productInfo.image" v-else />
            </div>
            <div class="text">
              <div class="line1">{{ item.productInfo.store_name }}</div>
              <div class="infor line1" v-if="item.productInfo.attrInfo">
                属性：{{ item.productInfo.attrInfo.suk }}
              </div>
              <div class="money">￥{{ item.truePrice }}</div>
            </div>
            <div class="carnum acea-row row-center-wrapper">
              <div
                class="reduce"
                :class="cartList.valid[index].cart_num <= 1 ? 'on' : ''"
                @click.prevent="reduce(index)"
              >
                -
              </div>
              <div class="num">
                <input
                  type="number"
                  v-model="item.cart_num"
                  class="ipt_num"
                  @input.prevent="specifiName(index)"
                  @blur.prevent="blurName(index)"
                />
              </div>
              <div
                class="plus"
                v-if="cartList.valid[index].attrInfo"
                :class="
                  cartList.valid[index].cart_num >=
                  cartList.valid[index].attrInfo.stock
                    ? 'on'
                    : ''
                "
                @click.prevent="plus(index)"
              >
                +
              </div>
              <div
                class="plus"
                v-else
                :class="
                  cartList.valid[index].cart_num >= cartList.valid[index].stock
                    ? 'on'
                    : ''
                "
                @click.prevent="plus(index)"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="invalidGoods" v-if="cartList.invalid.length > 0">
        <div class="goodsNav acea-row row-between-wrapper">
          <div @click="goodsOpen">
            <span
              class="iconfont"
              :class="goodsHidden === true ? 'icon-xiangyou' : 'icon-xiangxia'"
            ></span
            >失效商品
          </div>
          <div class="del" @click="delInvalidGoods">
            <span class="iconfont icon-shanchu1"></span>清空
          </div>
        </div>
        <div class="goodsList" :hidden="goodsHidden">
          <router-link
            :to="{ path: '/detail/' + item.product_id }"
            class="item acea-row row-between-wrapper"
            v-for="(item, index) in cartList.invalid"
            :key="index"
          >
            <div class="invalid acea-row row-center-wrapper">失效</div>
            <div class="pictrue">
              <img
                :src="item.productInfo.attrInfo.image"
                v-if="item.productInfo.attrInfo"
              />
              <img :src="item.productInfo.image" v-else />
            </div>
            <div class="text acea-row row-column-between">
              <div class="line1">{{ item.productInfo.store_name }}</div>
              <div class="infor line1" v-if="item.productInfo.attrInfo">
                属性：{{ item.productInfo.attrInfo.suk }}
              </div>
              <div class="acea-row row-between-wrapper">
                <div class="end">该商品已下架</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="noCart"
      v-if="cartList.valid.length === 0 && cartList.invalid.length === 0"
    >
      <div class="pictrue"><img src="@assets/images/noCart.png" /></div>
      <Recommend></Recommend>
    </div>
    <div style="height:2.1rem"></div>
    <div class="footer acea-row row-between-wrapper" v-if="cartList.valid.length>0">
      <div>
        <div class="select-btn">
          <div class="checkbox-wrapper">
            <label class="well-check">
              <input
                type="checkbox"
                name=""
                value=""
                :checked="isAllSelect && cartCount > 0"
                @click="allChecked"
              />
              <i class="icon"></i>
              <span class="checkAll">全选 ({{ cartCount }})</span>
            </label>
          </div>
        </div>
      </div>
      <div class="money acea-row row-middle" v-if="footerswitch === false">
        <span class="font-color-red">￥{{ countmoney }}</span>
        <div class="placeOrder bg-color-red" @click="placeOrder">立即下单</div>
      </div>
      <div class="button acea-row row-middle" v-else>
        <div class="bnt cart-color" @click="collectAll">收藏</div>
        <div class="bnt" @click="delgoods">删除</div>
      </div>
    </div>
  </div>
</template>
<script>
import Recommend from "@components/Recommend";
import {
  getCartList,
  postCartDel,
  changeCartNum,
  getCartCount
} from "@api/store";
import { postCollectAll } from "@api/user";
import { mul, add } from "@utils/bc";
import cookie from "@utils/store/cookie";
import debounce from "lodash.debounce";

const CHECKED_IDS = "cart_checked";

export default {
  name: "ShoppingCart",
  components: {
    Recommend
  },
  props: {},
  data: function() {
    return {
      cartList: { invalid: [], valid: [] },
      isAllSelect: false,
      cartCount: 0,
      countmoney: 0,
      goodsHidden: true,
      footerswitch: false,
      count: 0,
      checkedIds: [],
      loaded: false,
      index_num: 0
    };
  },
  watch: {
    $route(n) {
      if (n.name === "ShoppingCart") {
        this.carnum();
        this.countMoney();
        this.getCartList();
        this.gainCount();
        this.goodsHidden = true;
        this.footerswitch = false;
      }
    }
  },
  mounted: function() {
    let that = this;
    that.carnum();
    that.countMoney();
    that.getCartList();
    that.gainCount();
  },
  methods: {
    getCartList: function() {
      let that = this;
      getCartList().then(res => {
        that.cartList = res.data;
        let checkedIds = cookie.get(CHECKED_IDS) || [];
        if (!Array.isArray(checkedIds)) checkedIds = [];
        this.cartList.valid.forEach(cart => {
          if (checkedIds.length) {
            if (checkedIds.indexOf(cart.id) !== -1) cart.checked = true;
            else cart.checked = false;
          } else {
            cart.checked = true;
            that.checkedIds.push(cart.id);
          }
        });
        if (checkedIds.length) {
          that.checkedIds = checkedIds;
        }
        that.isAllSelect =
          that.checkedIds.length === this.cartList.valid.length;
        that.carnum();
        that.countMoney();
        this.loaded = true;
      });
    },
    //删除商品；
    delgoods: function() {
      let that = this,
        id = [],
        valid = [],
        list = that.cartList.valid;
      list.forEach(function(val) {
        if (val.checked === true) {
          id.push(val.id);
        }
      });
      if (id.length === 0) {
        that.$dialog.toast({ mes: "请选择产品" });
        return;
      }
      postCartDel(id).then(function() {
        list.forEach(function(val, i) {
          if (val.checked === false || val.checked === undefined)
            valid.push(list[i]);
        });
        that.$set(that.cartList, "valid", valid);
        that.carnum();
        that.countMoney();
        that.gainCount();
      });
    },
    // //获取数量
    gainCount: function() {
      let that = this;

      getCartCount().then(res => {
        that.count = res.data.count;
      });
    },
    //清除失效产品；
    delInvalidGoods: function() {
      let that = this,
        id = [],
        list = that.cartList.invalid;
      list.forEach(function(val) {
        id.push(val.id);
      });
      postCartDel(id).then(function() {
        list.splice(0, list.length);
        that.gainCount();
      });
    },
    //批量收藏;
    collectAll: function() {
      let that = this,
        data = { id: [], category: "" },
        list = that.cartList.valid;
      list.forEach(function(val) {
        if (val.checked === true) {
          data.id.push(val.product_id);
          data.category = val.type;
        }
      });
      if (data.id.length === 0) {
        that.$dialog.toast({ mes: "请选择产品" });
        return;
      }
      postCollectAll(data).then(function() {
        that.$dialog.toast({ mes: "收藏成功!" });
      });
    },
    //立即下单；
    placeOrder: function() {
      let that = this,
        list = that.cartList.valid,
        id = [];
      list.forEach(function(val) {
        if (val.checked === true) {
          id.push(val.id);
        }
      });
      if (id.length === 0) {
        that.$dialog.toast({ mes: "请选择产品" });
        return;
      }
      this.$router.push({ path: "/order/submit/" + id });
    },
    manage: function() {
      let that = this;
      that.footerswitch = !that.footerswitch;
    },
    goodsOpen: function() {
      let that = this;
      that.goodsHidden = !that.goodsHidden;
    },
    //加
    plus: function(index) {
      let that = this;
      let list = that.cartList.valid[index];
      list.cart_num++;
      if (list.attrInfo) {
        if (list.cart_num >= list.attrInfo.stock) {
          that.$set(list, "cart_num", list.attrInfo.stock);
        }
      } else {
        if (list.cart_num >= list.stock) {
          that.$set(list, "cart_num", list.stock);
        }
      }
      that.carnum();
      that.countMoney();
      that.syncCartNum(list);
    },
    specifiName(index) {
      let list = this.cartList.valid[index];
      this.index_num = index;
      this.carnum();
      this.countMoney();
      this.syncCartNum(list);
    },
    blurName(index) {
      let list = this.cartList.valid[index];
      if (list.cart_num < 1) {
        this.$set(list, "cart_num", 1);
      }
      this.carnum();
      this.countMoney();
      this.syncCartNum(list);
    },
    //减
    reduce: function(index) {
      let that = this;
      let list = that.cartList.valid[index];
      list.cart_num--;
      if (list.cart_num < 1) {
        that.$set(list, "cart_num", 1);
      }
      that.carnum();
      that.countMoney();
      that.syncCartNum(list);
    },
    syncCartNum(cart) {
      if (!cart.sync)
        cart.sync = debounce(() => {
          changeCartNum(cart.id, Math.max(cart.cart_num, 1) || 1);
        }, 500);

      cart.sync();
    },
    //单选
    switchSelect: function(index) {
      let that = this,
        cart = that.cartList.valid[index],
        i = this.checkedIds.indexOf(cart.id);
      cart.checked = !cart.checked;

      if (i !== -1) this.checkedIds.splice(i, 1);
      if (cart.checked) {
        this.checkedIds.push(cart.id);
      }
      let len = that.cartList.valid.length;
      let selectnum = [];
      for (let i = 0; i < len; i++) {
        if (that.cartList.valid[i].checked === true) {
          selectnum.push(true);
        }
      }
      that.isAllSelect = selectnum.length === len;
      that.$set(that, "cartList", that.cartList);
      that.$set(that, "isAllSelect", that.isAllSelect);
      cookie.set(CHECKED_IDS, that.checkedIds);
      that.carnum();
      that.countMoney();
    },
    //全选
    allChecked: function() {
      let that = this;
      let selectAllStatus = that.isAllSelect;
      selectAllStatus = !selectAllStatus;
      let checkedIds = [];
      // for (let i = 0; i < array.length; i++) {
      //   array[i].checked = selectAllStatus;
      //   checked.push()
      // }
      that.cartList.valid.forEach(cart => {
        cart.checked = selectAllStatus;
        if (selectAllStatus) checkedIds.push(cart.id);
      });
      that.$set(that, "cartList", that.cartList);
      that.$set(that, "isAllSelect", selectAllStatus);
      this.checkedIds = checkedIds;
      cookie.set(CHECKED_IDS, checkedIds);
      that.carnum();
      that.countMoney();
    },
    //数量
    carnum: function() {
      let that = this;
      var carnum = 0;
      var array = that.cartList.valid;
      for (let i = 0; i < array.length; i++) {
        if (array[i].checked === true) {
          if (array[i].cart_num) carnum += parseInt(array[i].cart_num);
        }
      }
      that.$set(that, "cartCount", carnum);
    },
    //总共价钱；
    countMoney: function() {
      let that = this;
      let carmoney = 0;
      let array = that.cartList.valid;
      for (let i = 0; i < array.length; i++) {
        if (array[i].checked === true) {
          carmoney = add(carmoney, mul(array[i].cart_num, array[i].truePrice));
        }
      }
      that.countmoney = carmoney;
    }
  }
};
</script>
<style scoped>
.ipt_num {
  width: 100%;
  display: block;
  line-height: 0.44rem;
  text-align: center;
}
</style>
 -->
