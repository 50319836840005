<template>
  <div class="search">
    <div class="header acea-row row-between-wrapper" ref="header">
      <div class="black" @click="returnChange()">
        <img src="@assets/images/icon_black.png" alt="" />
      </div>
      <p>搜索</p>
    </div>
    <div class="searchGood">
      <div class="search acea-row row-between-wrapper">
        <div class="input acea-row row-between-wrapper">
          <span class="iconfont icon-sousuo2"></span>
          <form @submit.prevent="submit"></form>
          <input type="text" placeholder="点击搜索商品" v-model="search" />
        </div>
        <div class="bnt" @click="submit">搜索</div>
      </div>
<!--       <div v-if="keywords.length">
        <div class="title">热门搜索</div>
        <div class="list acea-row">
          <div
            class="item"
            v-for="key of keywords"
            :key="key"
            @click="toSearch(key)"
          >
            {{ key }}
          </div>
        </div>
      </div> -->
      <div class="line"></div>
      <GoodList :goodList="goodList" :isSort="false"></GoodList>
      <Loading
        :loaded="loadend"
        :loading="loading"
        v-if="goodList.length"
      ></Loading>
    </div>
    <div
      class="noCommodity"
      v-cloak
      v-if="goodList.length === 0 && where.page > 1"
    >
      <div class="noPictrue">
        <img src="@assets/images/noSearch.png" class="image" />
      </div>
      <recommend></recommend>
    </div>
  </div>
</template>
<script>
import Loading from "@components/Loading";
import GoodList from "@components/GoodList";
import { getSearchKeyword } from "@api/store";
import { trim } from "@utils";
import { getProducts } from "@api/store";
import Recommend from "@components/Recommend";
export default {
  name: "GoodSearch",
  components: {
    GoodList,
    Loading,
    Recommend
    // Recommend,
    // GoodList
  },
  props: {},
  data: function() {
    return {
      keywords: [],
      search: "",
      where: {
        page: 1,
        limit: 20,
        keyword: ""
      },
      loading: false,
      loadend: false,
      goodList: []
    };
  },
  mounted: function() {
    this.getData();
  },
  methods: {
    //返回上一页
    returnChange() {
      var that = this;
      that.$router.go(-1);
    },
    submit() {
      const search = trim(this.search) || "";
      if (!search) return;
      this.where.keyword = search;
      this.$set(this, "goodList", []);
      this.where.page = 1;
      this.loadend = false;
      this.loading = false;
      this.getGoodlist();
    },
    // toSearch(s) {
    //   this.$router.push({ path: "/goods_list", query: { s } });
    // },
    getData() {
      getSearchKeyword().then(res => {
        this.keywords = res.data;
      });
    },
    toSearch: function(key) {
      this.where.keyword = key;
      this.$set(this, "goodList", []);
      this.where.page = 1;
      this.loadend = false;
      this.loading = false;
      this.getGoodlist();
    },
    getGoodlist: function() {
      let that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      let q = that.where;
      getProducts(q).then(res => {
        that.loading = false;
        that.goodList.push.apply(that.goodList, res.data);
        that.loadend = res.data.length < that.where.limit; //判断所有数据是否加载完成；
        that.where.page = that.where.page + 1;
      });
    }
  }
};
</script>
<style scoped>
.search { padding-top:30px; }

.search.router { background:#fff }
.search .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.search .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.search .header .black img {
  width: 100%;
  height: 100%;
}
.search .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
.noCommodity {
  border-top: 0.05rem solid #f5f5f5;
}
</style>
